<template>
  <div class="shop-open-setting container">
    <div class="flex">
      <p class="display-5">取餐方式：</p>
      <p class="display-5 text-main">
        {{ onGetShopSetting() }}
      </p>
    </div>
    <div class="flex">
      <p class="display-5">取貨方式：</p>
      <p class="display-5 text-main">
        {{ onGetRetailSetting() }}
      </p>
    </div>

    <p class="display-5">網路零售商店24小時營業，實體商店營業時間如下：</p>
    <ul class="open-time list-unstyled">
      <template
        v-for="(weeks, weeks_key) in shop_open_hours"
        :key="'hour_' + weeks_key"
      >
        <li v-if="weeks.status">
          {{ state.weeksName[weeks.day - 1] }}
          <span
            v-for="(hour, hour_key) in weeks.hours"
            :key="'hour_' + hour_key"
          >
            {{ hour.open }} ~ {{ hour.close }}
            <span v-if="hour_key !== weeks.hours.length - 1">、</span>
          </span>
        </li></template
      >
    </ul>
  </div>
</template>

<script>
import { computed, reactive } from "vue";
export default {
  props: {
    brandInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const shop_setting = computed(() => {
      const setting = props.brandInfo.setting?.other;
      return {
        for_here: setting?.dine_in || 0,
        to_go: setting?.to_go || 0,
        order_in: setting?.delivery || 0,
        limit: setting?.limit || 0,
        preparation_min: setting?.preparation_min || 0,
      };
    });
    const shop_open_hours = computed(
      () => props.brandInfo.setting?.business_hours
    );
    const shop_shipping = computed(() => props.brandInfo?.shipping);
    const state = reactive({
      weeksName: [
        "星期一",
        "星期二",
        "星期三",
        "星期四",
        "星期五",
        "星期六",
        "星期日",
      ],
    });

    const onGetShopSetting = () => {
      if (!shop_setting.value) return "";
      let msg = "";
      if (shop_setting.value.order.status === 1) msg += "現場點餐";
      if (shop_setting.value.here.status === 1) {
        if (msg !== "") msg += "、";
        msg += "預約內用";
      }
      if (shop_setting.value.togo.status === 1) {
        if (msg !== "") msg += "、";
        msg += "到店自取";
      }
      if (shop_setting.value.delivery.status === 1) {
        if (msg !== "") msg += "、";
        msg += "店家外送";
      }
      // if (shop_setting.value.for_here === 1) msg += "內用";
      // if (shop_setting.value.to_go === 1) {
      //   if (msg !== "") msg += "、";
      //   msg += "自取";
      // }
      // if (shop_setting.value.order_in === 1) {
      //   if (msg !== "") msg += "、";
      //   msg += "外送";
      // }
      return msg;
    };

    const onGetRetailSetting = () => {
      if (!shop_setting.value) return "";
      let msg = "";
      if (shop_shipping.value?.instore?.status === 1) msg += "自取";
      if (shop_shipping.value?.delivery?.status === 1) {
        if (msg !== "") msg += "、";
        msg += "宅配";
      }
      return msg;
    };
    return {
      onGetShopSetting,
      shop_open_hours,
      state,
      onGetRetailSetting,
      shop_setting,
    };
  },
};
</script>

<style lang="scss" scoped>
.shop-open-setting {
  background-color: var(--color-gray);
  padding-top: 16px;
  padding-bottom: 16px;

  .display-5 {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 8px;
  }
}
</style>
