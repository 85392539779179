<template>
  <div class="shop-info">
    <div class="shop-info-title">
      <div class="shop-info-title-text">本券店家基本資料</div>
    </div>

    <template v-if="shop_banners.length">
      <el-carousel :interval="5000" arrow="never">
        <el-carousel-item v-for="item in shop_banners" :key="item.image_id">
          <img class="banner" :src="item.image_url" alt="banner" />
        </el-carousel-item>
      </el-carousel>
    </template>

    <template v-if="shop_news">
      <content-title title="最新消息" />
      <!--
      <div class="content container">
        {{ shop_news }}
      </div>
      -->
      <div class="content container" v-html='shop_news.replace(/\n/g, "<br />\n")'>
      </div>
    </template>
    <shop-community :shopOther="brandInfo.other" />
    <shop-info-detail :brandInfo="brandInfo" />
    <shop-open-setting :brandInfo="brandInfo" />
    <copy-right />
  </div>
</template>

<script>
import { inject, computed } from "vue";
import ContentTitle from "@/components/ContentTitle.vue";
import CopyRight from "@/components/CopyRight.vue";
import ShopOpenSetting from "./ShopOpenSetting.vue";
import ShopInfoDetail from "./ShopInfoDetail.vue";
import ShopCommunity from "./ShopCommunity.vue";

export default {
  components: {
    ContentTitle,
    ShopOpenSetting,
    CopyRight,
    ShopInfoDetail,
    ShopCommunity,
  },
  props: {
    brandInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    // const shop_banners = inject("shop_banners");
    // const shop_news = inject("shop_news");

    const shop_banners = computed(() => props.brandInfo.brand_banners || []);
    const shop_news = computed(() => props.brandInfo.shop_news.replace(/\n/g, "<br />\n"));

    return {
      shop_banners,
      shop_news,
    };
  },
};
</script>

<style lang="scss" scoped>
.shop-info-title {
  border-top: 1px dashed var(--color-orange);
  position: relative;
  padding-bottom: 20px;

  .shop-info-title-text {
    position: absolute;
    background-color: var(--color-orange);
    width: 70%;
    text-align: center;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    font-size: 16px;
    min-height: 40px;
    line-height: 40px;
    color: #fff;
  }
}
.shop-info {
  & > * {
    margin-bottom: 24px;
  }

  & :last-child {
    margin-bottom: 0;
  }

  :deep(.el-carousel__button) {
    width: 12px;
  }
}
.banner {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
