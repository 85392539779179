export const promotionType = {
  0: "折價卷",
  1: "打折卷",
  2: "兌換卷",
  3: "升級卷",

  // 0: "打折",
  // 1: "折抵",
  // 2: "贈送商品",
  // 3: "升級商品",
};

export const couponKind = {
  2: "零售",
  1: "餐飲",
};

export const couponline = {
  0: "門市",
  1: "線上",
};
