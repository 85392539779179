<template>
  <div class="detail-info">
    <div class="title">優惠券名稱</div>
    <div class="info">{{ couponInfo.title }}</div>
    <div class="title">使用期限</div>
    <div class="info">{{ formateCouponDate(couponInfo) }}</div>
    <div class="title" v-if="couponInfo.description">優惠券說明</div>
    <div class="info">
      {{ couponInfo.description }}
    </div>
  </div>
</template>

<script>
import { formateToMinute } from "@/utils/tools";
export default {
  props: {
    couponInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const formateCouponDate = (item) => {
      if (!item?.expiry_date) return "-";
      return formateToMinute(item?.expiry_date) + "止";
    };
    return {
      formateCouponDate,
    };
  },
};
</script>

<style lang="scss" scoped>
.detail-info {
  .title {
    font-size: 16px;
    color: #222222;
    padding-top: 12px;
  }

  .info {
    font-size: 16px;
    color: #666666;
  }
}
</style>
