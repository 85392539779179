<template>
  <div class="content-title">
    <h2>{{ title }}</h2>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
  },
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.content-title {
  color: var(--color-primary);
  text-align: center;
  padding-top: 16px;

  @media (max-width: 525px) {
    h2 {
      font-size: 16px;
    }
  }
}
</style>
