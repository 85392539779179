<template>
  <div class="container">
    <div class="info-block">
      <img src="" alt="" />
      <span
        >{{ brandInfo.city }}{{ brandInfo.cityarea
        }}{{ brandInfo.address }}</span
      >
    </div>
    <div class="info-block" v-if="shop_phone">
      <img src="" alt="" />
      <span>{{ shop_phone }}</span>
    </div>
  </div>
</template>

<script>
import { inject } from "vue";
export default {
  props: {
    brandInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const shop_city = inject("shop_city");
    const shop_cityarea = inject("shop_cityarea");
    const shop_address = inject("shop_address");
    const shop_phone = inject("shop_phone");

    return {
      shop_city,
      shop_cityarea,
      shop_address,
      shop_phone,
    };
  },
};
</script>

<style lang="scss" scoped>
.info-block {
  border: 1px solid var(--color-border);
  padding: 12px;
  margin-bottom: 8px;
}
</style>
