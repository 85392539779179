<template>
  <div class="shop-community">
    <template v-for="item in arr" :key="item.text">
      <div class="communuty-icon" v-if="item.href">
        <a target="_blank" :href="item.href">
          <img :src="item.src" alt="" />
          <div>{{ item.text }}</div>
        </a>
      </div>
    </template>
  </div>
</template>

<script>
import { inject, computed } from "vue";
import web from "@/assets/images/start-social01.png";
import fb from "@/assets/images/start-social02.png";
import ig from "@/assets/images/start-social03.png";
import yt from "@/assets/images/start-social04.png";
import email from "@/assets/images/start-social05.png";
export default {
  props: {
    shopOther: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const shop_other = computed(() => props.shopOther);

    const arr = computed(() => [
      {
        href: shop_other.value?.website,
        src: web,
        text: "Website",
      },
      {
        href: shop_other.value?.facebook,
        src: fb,
        text: "Facebook",
      },
      {
        href: shop_other.value?.instagram,
        src: ig,
        text: "Instagram",
      },
      {
        href: shop_other.value?.youtube,
        src: yt,
        text: "Youtube",
      },
      {
        href: `mailto:${shop_other.value?.email}`,
        src: email,
        text: "E-mail",
      },
    ]);

    return {
      shop_other,
      arr,
    };
  },
};
</script>

<style lang="scss" scoped>
.shop-community {
  display: flex;
  flex-wrap: wrap;

  .communuty-icon {
    flex: 1;
    min-width: 70px;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;

    a {
      color: #666666;
      text-align: center;
    }
  }
}
</style>
