import {
  getCoupons,
  getCouponsHistory,
  getCouponsDetail,
  getCooperations,
  getCartCoupon,
  getCooperationCouponDetail,
} from "@/utils/http.api";

class CouponSevice {
  getCoupons(is_online, page, limit) {
    return getCoupons({ is_online, page, limit }).then((res) => {
      return res.data;
    });
  }
  getCouponDetail(id) {
    return getCouponsDetail(id).then((res) => {
      return res.data.data;
    });
  }
  getCooperationCouponDetail(id) {
    return getCooperationCouponDetail(id).then((res) => {
      return res.data.data;
    });
  }
  getCouponHistory(page, limit, is_online) {
    return getCouponsHistory({ is_online, page, limit }).then((res) => {
      return res.data;
    });
  }
  getCooperations(page, limit) {
    return getCooperations({ page, limit }).then((res) => {
      return res.data;
    });
  }
  getCartCoupon(kind) {
    console.log(kind);
    return getCartCoupon({ kind }).then((res) => {
      return res.data;
    });
  }
}

export default new CouponSevice();
