<template>
  <div class="coupon-detail">
    <div class="top">
      <div
        class="coupon-image no-image-back"
        :style="couponInfo.status === 2 && 'opacity: 0.5'"
      >
        <img
          v-if="couponInfo.image_url"
          :src="couponInfo.image_url"
          alt="back"
        />
      </div>
      <div class="coupon-block container">
        <h2 class="coupon-title">{{ couponInfo.brand_info?.name }}</h2>
        <coupon-item :item="couponInfo" :isDetail="true" />

        <template v-if="pathname !== '/cooperation-coupon-detail'">
          <!-- 線下 -->
          <template v-if="couponInfo.is_online === 0">
            <coupon-detail-qrcode :value="qrcodeValue" />
          </template>
          <!-- 線上 -->
          <div class="offline-button" v-else>
            <template v-if="couponInfo.kind === 1">
              <a href="/home" class="btn btn-block btn-main"> 立即點餐 </a>
            </template>
            <template v-else>
              <div class="hint">只要在線上商店消費，即可搭配使用此優惠券!</div>
              <a href="/shop/category" class="btn btn-block btn-main">
                零售商店
              </a>
            </template>
          </div>
        </template>

        <div v-if="couponInfo?.last_count" class="coupon-tooltip">
          此為限量優惠券，尚有{{ couponInfo.last_count }}份，請儘速使用!
        </div>
        <coupon-detail-info :couponInfo="couponInfo" />
      </div>
    </div>
    <shop-info :brandInfo="couponInfo?.brand_info" />
    <div
      v-if="
        pathname === '/cooperation-coupon-detail' &&
        Object.keys(couponInfo).length > 0
      "
      class="cooperation-button"
    >
      <el-button type="primary" @click="isShow = true">領取優惠券</el-button>
      <cooperation-dialog
        v-model="isShow"
        :brandInfo="couponInfo.brand_info"
        :couponId="couponInfo.coupon_id"
        :cooperationCouponId="couponInfo.cooperation_id"
      />
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import CouponService from "@/services/coupon.service";

import CouponItem from "@/components/CouponItem.vue";
import ShopInfo from "@/components/shop-info/index.vue";
import CouponDetailQrcode from "./components/CouponDetailQrcode.vue";
import CouponDetailInfo from "./components/CouponDetailInfo.vue";
import CooperationDialog from "./components/CooperationDialog.vue";

export default {
  name: "CouponDetail",
  components: {
    CouponItem,
    CouponDetailQrcode,
    CouponDetailInfo,
    ShopInfo,
    CooperationDialog,
  },
  setup() {
    const route = useRoute();
    const store = useStore();
    const couponInfo = ref({});
    const isShow = ref(false);

    const pathname = route.path.replace(/\/(\d+)/, "");
    if (pathname === "/cooperation-coupon-detail") {
      CouponService.getCooperationCouponDetail(route.params.id).then((res) => {
        couponInfo.value = res || {};
      });
    } else {
      CouponService.getCouponDetail(route.params.id).then((res) => {
        couponInfo.value = res || {};
      });
    }

    store.dispatch("member/getProfile");
    const currentUser = computed(() => {
      return store.state.member.profile;
    });

    const qrcodeValue = computed(() => {
      if (Object.keys(currentUser.value).length === 0) return;
      if (Object.keys(couponInfo.value).length === 0) return;
      return JSON.stringify({
        type: "coupon",
        member_id: currentUser.value.id,
        member_coupon_id: couponInfo.value.id,
      });
    });

    return {
      couponInfo,
      qrcodeValue,
      pathname,
      isShow,
    };
  },
};
</script>

<style lang="scss" scoped>
.top {
  background-color: #f8f8f8;
}
.coupon-image {
  width: 100%;
  min-height: 126px;
  max-height: 300px;
  overflow: hidden;
  img {
    height: 100%;
    object-fit: cover;
  }
}

.coupon-block {
  position: relative;
  top: -80px;
  .coupon-title {
    font-size: 22px;
    color: #fff;
  }

  .coupon-tooltip {
    color: #e02020;
    font-size: 17px;
    text-align: center;
  }
}

.offline-button {
  padding: 32px 0;
  a {
    max-width: 190px;
    margin: 0 auto;
  }
}
.cooperation-button {
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 480px;
  padding: 16px;
  z-index: 3;
  background-color: #fff;

  .el-button {
    width: 100%;
  }

  :deep(.el-dialog) {
    max-width: 480px;
  }
}

.hint {
  color: var(--color-primary);
  text-align: center;
  padding-bottom: 8px;
}
</style>
