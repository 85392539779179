<template>
  <el-dialog
    v-model="dialogVisible"
    title="領取優惠卷"
    width="90%"
    :before-close="handleClose"
    center
  >
    <div>您將離開本店，並前往「 {{ brandInfo.name }} 」</div>
    <div>LINE官方帳號</div>
    <div>1、若未加入好友，請先加入官方帳號好友</div>
    <div>2、若已加入好友，請至「 {{ brandInfo.name }} 」會員中心查看使用</div>
    <template #footer>
      <el-button
        style="width: 100%"
        type="primary"
        :loading="isLoading"
        @click="btnClick"
      >
        立即前往
      </el-button>
    </template>
  </el-dialog>
</template>

<script>
import { ref, computed } from "vue";
import { useRouter } from "vue-router";
export default {
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    brandInfo: {
      type: Object,
      default: () => ({}),
    },
    cooperationCouponId: {
      type: Number,
    },
    couponId: {
      type: Number,
    },
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const dialogVisible = computed({
      get() {
        return props.modelValue;
      },
      set(newValue) {
        emit("update:modelValue", newValue);
      },
    });
    const isLoading = ref(false);

    const handleClose = () => emit("update:modelValue", false);

    const btnClick = () => {
      // console.log(
      //   `https://${props.brandInfo.domain}?cooperation_id=${props.id}`
      // );
      window.location.href = `https://${props.brandInfo.domain}?cooperation_id=${props.cooperationCouponId}&coupon_id=${props.couponId}`;
    };

    return {
      dialogVisible,
      handleClose,
      isLoading,
      btnClick,
    };
  },
};
</script>

<style lang="scss" scoped></style>
