<template>
  <div class="copy-right">Powered by OKSHOP.tw</div>
</template>

<script>
export default {
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.copy-right {
  color: #00000066;
  font-size: 12px;
  text-align: center;
  padding: 12px 0 12px 0;
  background-color: #ffffff;
}
</style>
