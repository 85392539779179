<template>
  <div class="coupon-qrcode">
    <qrcode-vue
      :value="value"
      :size="180"
      :level="qrcode_level"
      :renderAs="qrcode_renderAs"
      class="small-code"
      v-if="value"
    />
    <div v-else style="height: 180px"></div>
    <h2>請出示QR碼給店員</h2>
  </div>
</template>

<script>
import QrcodeVue from "qrcode.vue";
export default {
  components: {
    QrcodeVue,
  },
  props: {
    value: {
      type: String,
      default: "",
    },
    qrcode_level: {
      type: String,
      default: "L",
    },
    qrcode_renderAs: {
      type: String,
      default: "canvas",
    },
    qrcode_class: {
      type: String,
      default: "qrcode",
    },
  },
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.coupon-qrcode {
  text-align: center;
  padding: 32px 0;

  h2 {
    color: var(--color-primary);
    margin-top: 24px;
    font-size: 17px;
  }
}
</style>
