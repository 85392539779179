<template>
  <div class="coupon-item">
    <div class="card-con">
      <div class="coupon-card">
        <div class="left">
          <div class="text">{{ promotionType[item.promotion_type] }}</div>
          <img
            class="coupon-img"
            v-if="item.image_url"
            :src="item.image_url"
            alt="image"
          />
        </div>
        <div class="content">
          <div class="sub-title over-text">{{ item.title }}</div>
          <el-tag size="mini">
            {{ couponKind[item.kind] }}{{ couponline[item.is_online] }}券
          </el-tag>
          <div class="flex" v-if="item.last_count">
            <el-tag size="mini" type="danger">限量</el-tag>
            <span class="red-mark over-text">
              尚有{{ item.last_count }}份可兌換
            </span>
          </div>
          <div class="small-mark over-text">
            使用期限：{{ formateCouponDate(item) }}
          </div>
        </div>
        <div class="right center" @click="cardClick" v-if="!isDetail">
          <el-button type="primary" size="small">查看</el-button>
        </div>
      </div>
    </div>
    <!-- 已使用 -->
    <div v-if="item.status === 2" class="icon-mask center">
      <img src="@/assets/images/is-used.svg" alt="icon" />
    </div>
    <!-- 已失效 -->
    <div v-else-if="item.status === 0" class="icon-mask center">
      <img src="@/assets/images/is-invalid.svg" alt="icon" />
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { formateToMinute } from "@/utils/tools";
import { promotionType, couponline, couponKind } from "@/constants/coupon";
export default {
  props: {
    isDetail: {
      type: Boolean,
      default: false,
    },
    isCooperation: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const router = useRouter();

    const cardClick = () => {
      if (props.isCooperation) {
        return router.push(`/cooperation-coupon-detail/${props.item?.id}`);
      }
      router.push(`/coupon-detail/${props.item?.id}`);
    };

    const formateCouponDate = (item) => {
      if (!item?.expiry_date) return "-";
      return formateToMinute(item?.expiry_date) + "止";
    };

    return {
      cardClick,
      promotionType,
      couponline,
      couponKind,
      formateCouponDate,
    };
  },
};
</script>

<style lang="scss" scoped>
.coupon-item {
  position: relative;

  .icon-mask {
    position: absolute;
    pointer-events: none;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #ffffff98;
  }

  .card-con {
    filter: drop-shadow(0.5rem 0.5rem 0.2rem #b8b8b8d3);
  }

  .coupon-card {
    height: 115px;
    border-radius: 0.5rem;
    background: #fff9f4;
    mask-image: radial-gradient(circle at 4px, transparent 4px, red 4.5px);
    mask-position: -4px;
    mask-size: 100% 12px;
    overflow: hidden;
    display: flex;

    .left {
      height: 100%;
      width: 40%;
      max-width: 110px;
      background: linear-gradient(135deg, #f89321, #fa5700, #b33434);
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      .text {
        font-size: 17px;
        color: #fff;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .coupon-img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .content {
      flex: 1;
      overflow: hidden;
      padding: 8px;
    }

    .right {
      padding-right: 10px;
    }
  }
}
</style>
